document.addEventListener('DOMContentLoaded', function () {

    const aboutSwiper = new Swiper(".about-swiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: ".about .swiper-button-next",
            prevEl: ".about .swiper-button-prev",
        },
        /* breakpoints: {
            1150: {
                slidesPerView: 3,
            },
        }, */
    });

    const sliderGalleryWrap = document.querySelectorAll(".management-company");

    sliderGalleryWrap.forEach((sliderGallery) => {
        const swiperGallery = new Swiper(sliderGallery.querySelector(".gallery-slider"), {
            loop: true,
            spaceBetween: 30,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: sliderGallery.querySelector(".swiper-button-next"),
                prevEl: sliderGallery.querySelector(".swiper-button-prev"),
            },
            breakpoints: {
                1280: {
                    slidesPerView: 4,
                },
                300: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
            },
        });
        const swiper2Gallery = new Swiper(sliderGallery.querySelector(".gallery-slider2"), {
            loop: true,
            spaceBetween: 10,
            navigation: {
                nextEl: sliderGallery.querySelector(".swiper-button-next"),
                prevEl: sliderGallery.querySelector(".swiper-button-prev"),
            },
            thumbs: {
                swiper: swiperGallery,
            },
        });
    });



    const advantagesSwiper = new Swiper(".advantages-swiper", {
        slidesPerView: 4,
        spaceBetween: 40,
        speed: 1000,
        navigation: {
            nextEl: ".advantages .swiper-button-next",
            prevEl: ".advantages .swiper-button-prev",
        },
        breakpoints: {
            1280: {
                slidesPerView: 4,
            },
            300: {
                slidesPerView: 1.4,
                loop: true,
            },
        },
    });



    const sliderSwiper = document.querySelectorAll(".slider-swiper");

    sliderSwiper.forEach((slider) => {
        const swiper = new Swiper(slider, {
            slidesPerView: 3,
            loop: true,
            speed: 1200,
            spaceBetween: 0,
            centeredSlides: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: slider.nextElementSibling.querySelector(".swiper-button-next"),
                prevEl: slider.nextElementSibling.querySelector(".swiper-button-prev"),
            },
            breakpoints: {
                1280: {
                    slidesPerView: 3,
                },
                300: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
            },
        });

        document.querySelectorAll(".tab").forEach((tab) => {
            tab.addEventListener("click", () => {
                swiper.update();
            });
        });


        if (window.innerWidth > 1280) {
            function updateScale(isChanging) {
                swiper.slides.forEach((slide) => {
                    const progress = slide.progress;
                    const absProgress = Math.abs(progress);
                    const scale = Math.max(1 - (absProgress * 0.2), 0.8);
                    const translateX = progress * 205;
                    const translateY = absProgress * 10;
                    const transitionDuration = isChanging ? '1.4s' : '0s';


                    slide.style.transition = `transform ${transitionDuration} cubic-bezier(0.24, 0.88, 0.42, 1.01)`;
                    slide.style.transform = `translateX(${translateX / 1.05}%) scale(${scale})`;
                });
            }

            function resetTransition() {
                swiper.slides.forEach((slide) => {
                    slide.style.transition = 'transform 0s';
                });
            }

            swiper.on('init', () => updateScale(false));
            swiper.on('setTranslate', () => updateScale(false));
            swiper.on('slideChangeTransitionStart', () => updateScale(true));
            swiper.on('slideChangeTransitionEnd', () => updateScale(true));
            swiper.on('touchStart', resetTransition);
            swiper.on('paginationHide', resetTransition);
            swiper.on('paginationShow', resetTransition);
            swiper.on('paginationChange', () => {
                resetTransition();
                setTimeout(() => updateScale(true), 0);
            });

            updateScale(true);
            resetTransition();


        }
    });



    const constructionSwiper = new Swiper(".construction-swiper", {
        slidesPerView: 3,
        spaceBetween: 10,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            1280: {
                slidesPerView: 3,
            },
            300: {
                slidesPerView: 1,
            },
        },
    });

    let docText = document.querySelector('.construction__date p');

    function textSlide() {
        let doc = document.querySelector('.construction .swiper-slide-active');

        if (doc) {
            let text = doc.getAttribute('data-text');
            docText.textContent = text;
        }
    }

    textSlide();
    constructionSwiper.on('slideChangeTransitionEnd', textSlide);

    const promoSwiper = new Swiper(".promo-swiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        navigation: {
            nextEl: ".promo .swiper-button-next",
            prevEl: ".promo .swiper-button-prev",
        },
    });




});




