function menu() {
    const burger = document.querySelector('.burger');
    const menu = document.querySelector('.header__right');
    const menuLink = document.querySelectorAll('.nav a');

    burger.addEventListener('click', function () {
        this.classList.toggle('active');
        menu.classList.toggle('active');
        document.body.classList.toggle('scroll-hide');
    });

    menuLink.forEach(function (link) {
        link.addEventListener('click', function () {
            burger.classList.remove('active');
            menu.classList.remove('active');
            document.body.classList.remove('scroll-hide');
        });
    });



}

menu();