function headerHide() {
    const header = document.querySelector(".header");
    let scrolledWindow = scrollY;
    let scrollPrev = 0;

    if (scrolledWindow > 10) {
        header.classList.add('header-white');
    } else {
        header.classList.remove('header-white');
    }


    window.addEventListener('scroll', function () {
        let scrolled = scrollY;

        if (scrolled > 10) {
            header.classList.add('header-white');
        } else {
            header.classList.remove('header-white');
        }

        scrollPrev = scrolled;
    });
}

if (document.querySelector(".header")) {
    headerHide();
}