
document.querySelectorAll('a.scroll-to').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const headerHeight = document.querySelector('header').offsetHeight;
        const target = document.querySelector(this.getAttribute('href'));
        const elementPosition = target.offsetTop;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    });
});