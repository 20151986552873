document.addEventListener('DOMContentLoaded', function () {

    $('.tab').on('click', function (event) {
        event.preventDefault();

        $($(this).siblings()).removeClass('tab-active');
        $($(this).parents('.sec-tab').find('.tabs-content')).removeClass('tabs-content-active');

        $(this).addClass('tab-active');
        $($(this).attr('href')).addClass('tabs-content-active');
    });

});


$("input[type=tel]").mask("+380 (99) 999 99 99");

$('.alert-modal__close').on('click', function () {
    $('.alert-modal').removeClass('active');
});



